import React from 'react'
import { useRouter } from 'next/router'
import ReactHtmlParser from 'react-html-parser'
import { Container, Row, Col } from 'reactstrap'

import { UsersApi } from 'typescript-axios'
import { UserContext } from '../contexts/UserContext'
import { getAxiosParams } from '../utils/client'
import withLocale from '../hocs/WithLocale'
import { translate } from '../translations/utils'
import { logout } from '../utils/session'
import Signin from '../components/Signin'
import useTranslation from '../hooks/UseTranslation'

const USER_SERVICE = new UsersApi(getAxiosParams())

export async function getStaticProps(ctx) {
  const { locale, defaultLocale } = ctx
  return {
    props: {
      title: translate('login_page_title', locale, defaultLocale),
      hideHeader: true,
      hideFooter: true,
      noPaddingTop: true,
      locale,
      defaultLocale
    }
  }
}

const Page = () => {
  const { t } = useTranslation()
  const { loggedUser, setLoggedUser } = React.useContext(UserContext)

  const router = useRouter()
  const { redirectUrl } = router.query

  const redirect = () => {
    if (loggedUser) {
      // prevents loop of death
      USER_SERVICE.readUserMeApiV1UsersMeGet()
        .then((res) => {
          if (redirectUrl) {
            router.replace(redirectUrl)
          } else router.replace(loggedUser.is_guru ? '/guru' : '/my-teachers')
        })
        .catch((e) => {
          if (e.response.status == 401 || e.response.status == 404) {
            logout(setLoggedUser)
          }
        })
    }
  }

  React.useEffect(() => {
    if (router.isReady) redirect()
  }, [loggedUser, router.isReady])

  return (
    <Container fluid className='px-3'>
      <Row className='min-vh-100'>
        <Col md='8' lg='6' xl='5' className='d-flex align-items-center'>
          <div className='w-100 py-5 px-md-5 px-xl-6 position-relative'>
            <div className='mb-5'>
              <img
                src='/content/svg/logo-square.svg'
                alt='...'
                style={{ maxWidth: '4rem' }}
                className='img-fluid mb-3'
              />
              <h2>{ReactHtmlParser(t('login_welcome_back'))}</h2>
            </div>
            <Signin
              showProgress
              onClose={() => router.back()}
              onSignupClick={() =>
                router.push({ pathname: '/signup', query: { ...router.query } })
              }
            />
          </div>
        </Col>
        <Col md='4' lg='6' xl='7' className='d-none d-md-block'>
          <div
            style={{
              backgroundImage:
                'url(content/img/yoga-bg-right.jpg)'
            }}
            className='bg-cover h-100 mr-n3'
          />
        </Col>
      </Row>
    </Container>
  )
}

export default withLocale(Page)
