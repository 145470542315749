import React from 'react'
import {
  Row,
  Col,
  Button,
  Form,
  Input,
  Label,
  FormGroup,
  Collapse,
  Alert
} from 'reactstrap'
import ReactHtmlParser from 'react-html-parser'
import { LoginApi, UsersApi } from 'typescript-axios'
import { firstLetter } from 'common'
import { UserContext } from '../contexts/UserContext'

import FacebookLogin from './FacebookLogin'

import { getAxiosParams } from '../utils/client'
import useTranslation from '../hooks/UseTranslation'
import { logout, fbLogout } from '../utils/session'
import ToastAccountCreated from './ToastAccountCreated'

const USER_SERVICE = new UsersApi(getAxiosParams())
const LOGIN_SERVICE = new LoginApi(getAxiosParams())

const Signin = (props) => {
  const { t } = useTranslation()
  const { setLoggedUser } = React.useContext(UserContext)

  const [isLoggingIn, setIsLoggingIn] = React.useState(false)
  const [showSignupToast, setShowSignupToast] = React.useState(false)
  const [showResetPassword, setShowResetPassword] = React.useState(false)
  const [alert, setAlert] = React.useState({})

  React.useEffect(() => {
    setAlert({})
  }, [showResetPassword])

  const handleLoginResponseOk = (user) => {
    if (!user) {
      // ugly but what can you do
      user = USER_SERVICE.readUserMeApiV1UsersMeGet().then((res) => res.data)
    }
    setLoggedUser(user)
    props.callback && props.callback()
  }

  const handleLoginResponseCreds = (response) => {
    if (response.status < 200 || response.status >= 300) {
      setAlert({
        color: 'danger',
        msg:
          response.status == 467 ? t('login_error_bad_creds') : t('login_error')
      })
    } else {
      USER_SERVICE.readUserMeApiV1UsersMeGet()
        .then((res) => handleLoginResponseOk(res.data))
        .catch((e) => {
          if (e.response.status == 401 || e.response.status == 404) {
            logout(setLoggedUser)
          }
        })
    }
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    setIsLoggingIn(true)
    const username = e.target.querySelector('[name="loginUsername"]').value
    if (showResetPassword) {
      const response =
        await LOGIN_SERVICE.recoverPasswordApiV1PasswordRecoveryEmailPost(
          username
        )
          .then((res) => res)
          .catch((e) => e.response)
      if (response.status < 200 || response.status >= 300) {
        setAlert({
          color: 'danger',
          msg: response.data.detail ?? response.data.msg
        })
      } else {
        setAlert({ color: 'success', msg: response.data.msg })
      }
    } else {
      const password = e.target.querySelector('[name="loginPassword"]').value
      const response =
        await LOGIN_SERVICE.loginAccessTokenApiV1LoginAccessTokenPost(
          username,
          password
        )
          .then((res) => res)
          .catch((e) => e.response)

      handleLoginResponseCreds(response)
    }
    setIsLoggingIn(false)
  }

  return (
    <div>
      <ToastAccountCreated
        isOpen={showSignupToast}
        callback={handleLoginResponseOk}
        showProgress={props.showProgress}
      />
      <Form className='form-validate' onSubmit={onSubmit}>
        <FormGroup>
          <Row>
            <Col>
              <Label for='loginUsername' className='form-label'>
                {t('email_address')}
              </Label>
            </Col>
            {showResetPassword && (
              <Col xs='auto'>
                <a
                  href='#'
                  className='form-text small'
                  onClick={() => setShowResetPassword(false)}
                >
                  {t('login_sign_in')}
                </a>
              </Col>
            )}
          </Row>
          <Input
            name='loginUsername'
            id='loginUsername'
            type='email'
            placeholder={t('email_dummy')}
            autoComplete='email'
            required
          />
        </FormGroup>
        {!showResetPassword && (
          <FormGroup className='mb-4'>
            <Row>
              <Col>
                <Label for='loginPassword' className='form-label'>
                  {t('password')}
                </Label>
              </Col>
              <Col xs='auto'>
                <a
                  href='#'
                  className='form-text small'
                  onClick={() => setShowResetPassword(true)}
                >
                  {t('login_forgot_password')}
                </a>
              </Col>
            </Row>
            <Input
              name='loginPassword'
              id='loginPassword'
              autocomplete='current-password'
              type='password'
              placeholder={firstLetter(t('password'))}
              required
            />
          </FormGroup>
        )}
        {/* <FormGroup className="mb-4">
                                <CustomInput
                                    name="loginRemember"
                                    id="loginRemember"
                                    type="checkbox"
                                    className="text-muted"
                                    label={<span className="text-sm">Remember me for 30 days</span>}
                                />
                            </FormGroup> */}
        <Button disabled={isLoggingIn} size='lg' color='primary' block>
          {showResetPassword ? t('login_reset_password') : t('login_sign_in')}
        </Button>
        {!showResetPassword && (
          <div>
            <div className='text-muted mt-2 text-center'>
              <div>{t('or')}</div>
            </div>
            <div className='mt-2'>
              <FacebookLogin
                size='lg'
                onSuccess={setLoggedUser}
                isSignUp={false}
                onUserDoesNotExist={() => {
                  fbLogout()
                  setAlert({
                    color: 'warning',
                    msg: ReactHtmlParser(t('login_error_user_does_not_exist'))
                  })
                }}
                onError={(res) => {
                  fbLogout()
                  setAlert({
                    color: 'danger',
                    msg: res.data.detail ?? t('login_error')
                  })
                }}
                isGuru={false}
              />
            </div>
          </div>
        )}
        {!showResetPassword && (
          <div
            className='mt-2 text-sm text-muted'
            dangerouslySetInnerHTML={{ __html: t('login_accepts_terms') }}
          />
        )}
        {/* <hr
                                data-content="OR"
                                className="my-3 hr-text letter-spacing-2"
                            />
                            <Button
                                color="outline-primary"
                                block
                                className="btn-social mb-3"
                            >
                                <i className="fa-2x fa-facebook-f fab btn-social-icon" />
                                Connect
                                <span className="d-none d-sm-inline">
                                    with Facebook
                                </span>
                            </Button>
                            <Button
                                color="outline-muted"
                                block
                                className="btn-social mb-3"
                            >
                                <i className="fa-2x fa-google fab btn-social-icon" />
                                Connect
                                <span className="d-none d-sm-inline">
                                    with Google
                                </span>
                            </Button> */}
        <Collapse isOpen={alert.msg}>
          <Alert color={alert.color} className='mt-2 text-center'>
            {alert.msg}
          </Alert>
        </Collapse>
        <hr className='my-4' />
        <p className='text-center text-muted'>
          {t('login_no_account')}&nbsp;
          <a
            className='text-primary'
            style={{ cursor: 'pointer' }}
            onClick={props.onSignupClick}
          >
            <strong>{t('login_sign_up')}</strong>
          </a>
        </p>
      </Form>
      {props.onClose && (
        <a
          className='close-absolute mr-md-5 mr-xl-6 pt-5 text-primary'
          style={{ cursor: 'pointer' }}
          onClick={props.onClose}
        >
          <svg className='svg-icon w-3rem h-3rem'>
            <use xlinkHref='/content/svg/orion-svg-sprite.svg#close-1' />
          </svg>
        </a>
      )}
    </div>
  )
}

export default Signin
