import React from 'react'
import { Toast, ToastBody, ToastHeader } from 'reactstrap'
import ReactHtmlParser from 'react-html-parser'
import ProgressUniform from './ProgressUniform'
import useTranslation from '../hooks/UseTranslation'

const TOAST_TIMEOUT = 4000

const ToastAccountCreated = (props) => {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = React.useState(props.isOpen)

  React.useEffect(() => {
    if (props.isOpen && !props.showProgress) {
      // FIXME: looks horrible
      props.callback()
      setTimeout(() => setIsOpen(false), TOAST_TIMEOUT)
    }
    setIsOpen(props.isOpen)
  }, [props.isOpen])

  return (
    <div className='p-3' className='signup-toast'>
      <Toast isOpen={isOpen} style={{ backgroundColor: 'white' }}>
        <ToastHeader>
          <div className='text-primary p-2 text-lg'>
            {t('account_created_title')}
          </div>
        </ToastHeader>
        <ToastBody>
          <div className='px-2 mb-2'>
            {ReactHtmlParser(t('account_created_subtitle'))}
          </div>
          {isOpen && props.showProgress && (
            <ProgressUniform
              animated
              timeout={TOAST_TIMEOUT}
              onEnd={() => {
                props.callback && props.callback()
                setIsOpen(false)
              }}
            />
          )}
        </ToastBody>
      </Toast>
    </div>
  )
}

export default ToastAccountCreated
